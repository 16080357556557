<template>
  <div class="admin-form p-6">
    <div class="flex justify-between mb-6">
      <div class="title text-black text-base lg:text-xl leading-5 lg:leading-7 font-semibold">Modifier un utilisateur</div>
        <button
          class="close focus:outline-none"
          @click.prevent="$parent.$emit('close')"
        >
          <IconClose />
        </button>
    </div>
    <div class="body">
      <ValidationObserver ref="form" tag="div"  class="">
          <form @submit.prevent="onUpdateAdmin">
            <div class="mb-5">
                  <ValidationProvider name="L'identifiant" rules="required" v-slot="{ errors }">
                      <m-input name = "identifiant" :errors="errors[0]" v-model="adminData.identifier" label="Identifiant"/>
                  </ValidationProvider>
              </div>
              <div class="mb-5">
                  <ValidationProvider rules="required|email" name = "L'email" v-slot="{ errors }">
                      <m-input name = "mail" :errors="errors[0]" v-model="adminData.email" label="Email"/>
                  </ValidationProvider>
              </div>
              <div class="mb-5">
                  <ValidationProvider name="Le nom" rules="required" v-slot="{ errors }">
                      <m-input name = "name" :errors="errors[0]" v-model="adminData.lastName" label="Nom"/>
                  </ValidationProvider>
              </div>
              <div class="mb-5">
                  <ValidationProvider name="Le(s) prénom(s)" rules="required" v-slot="{ errors }">
                      <m-input name = "firstName" :errors="errors[0]" v-model="adminData.firstName" label="Prénoms"/>
                  </ValidationProvider>
              </div>
              <div class="grid grid-cols-2 gap-4">
                <base-button @click="$parent.$emit('close')" full text="Annuler" color="gray" />
                <base-button type="submit" text="Valider" :loading="loading" :disabled="loading" color="primary" full />
              </div>
          </form>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
import BaseButton from '@/components/globalComponents/BaseButton.vue'

export default {
  components: {
    BaseButton
  },
  props: {
    user: {
      type: Object,
      default() {
        return {}
      }
    }
  },

  data(){
    return {
      adminData: {},
      loading: false
    }
  },

  methods: {
    updateAdmin() {
      this.$refs.form.validateWithInfo().then(validate => {
          let { isValid, errors } = validate;
          console.log(isValid, errors)
          if (isValid){
            this.loading = true
            this.$store.dispatch('admin/updateAdmin', {id: this.user.id, adminData: this.adminData })
              .then((response) => {
                this.$emit('success',response)
              })
              .catch((error) => {
                error
                this.$emit('failed', error)
              })
              .finally(() => {
                this.loading = false
              })
          }
      });
    }
  },

  mounted () {
    this.adminData = this.user
  }
}
</script>

<style>

</style>