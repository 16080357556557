<template>
  <div class="delete-confirmation p-6">
    <div class="flex justify-between mb-6">
      <div class="title text-black text-base lg:text-xl leading-5 lg:leading-7 font-semibold"></div>
        <button
          class="close focus:outline-none"
          @click.prevent="$parent.$emit('close')"
        >
          <IconClose />
        </button>
    </div>
    <div class="body">
      <div class="flex flex-col justify-center">
        <div class="flex justify-center">
          <svg width="121" height="121" viewBox="0 0 121 121" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M60.4998 11.75C33.6193 11.75 11.7498 33.6195 11.7498 60.5C11.7498 87.3805 33.6193 109.25 60.4998 109.25C87.3802 109.25 109.25 87.3805 109.25 60.5C109.25 33.6195 87.3802 11.75 60.4998 11.75ZM85.8708 44.1617L54.3708 81.6617C54.0253 82.0733 53.5954 82.4058 53.1101 82.6367C52.6249 82.8676 52.0957 82.9915 51.5583 83H51.4951C50.9695 82.9998 50.4497 82.8891 49.9697 82.6751C49.4896 82.4611 49.0599 82.1486 48.7083 81.7578L35.2083 66.7578C34.8655 66.3942 34.5988 65.9657 34.4239 65.4975C34.249 65.0293 34.1695 64.5309 34.19 64.0315C34.2105 63.5322 34.3306 63.0419 34.5433 62.5896C34.7559 62.1374 35.0569 61.7322 35.4284 61.3978C35.7999 61.0635 36.2344 60.8068 36.7066 60.6429C37.1787 60.4789 37.6788 60.411 38.1776 60.443C38.6763 60.4751 39.1636 60.6066 39.6108 60.8297C40.0581 61.0528 40.4562 61.363 40.7818 61.7422L51.3966 73.5359L80.1287 39.3383C80.7731 38.5932 81.685 38.1316 82.667 38.0533C83.6491 37.9751 84.6225 38.2864 85.3769 38.9201C86.1312 39.5537 86.6059 40.4588 86.6984 41.4396C86.7908 42.4205 86.4935 43.3983 85.8708 44.1617Z" fill="#27AE60"/>
          </svg>
        </div>
        <div class="mb-5 text-center">
          Une invitation à été envoyé à {{user.email}}
        </div>
      </div>
      <div class="flex flex-center">
        <base-button @click="$parent.$emit('close')" full text="Terminer" color="primary" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    user: {
      type: Object,
      defaut(){
        return {}
      }
    }
  },
}
</script>

<style>

</style>