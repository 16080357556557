<template>
  <div class="delete-confirmation p-6">
    <div class="flex justify-between mb-6">
      <div class="title text-black text-base lg:text-xl leading-5 lg:leading-7 font-semibold">Supprimer le compte</div>
        <button
          class="close focus:outline-none"
          @click.prevent="$parent.$emit('close')"
        >
          <IconClose />
        </button>
    </div>
    <div class="body">
      <div class="mb-5">
        Voulez-vous supprimer l'utilisateur {{user.lastName}} {{user.firstName}} ?
      </div>
      <div class="grid grid-cols-2 gap-4">
        <base-button @click="$parent.$emit('close')" full text="Annuler" color="gray" />
        <base-button @click="onDeleteUser" text="Valider" :loading="loading" :disabled="loading" color="primary" full />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    user: {
      type: Object,
      defaut(){
        return {}
      }
    }
  },

  data(){
    return {
      loading: false
    }
  },

  methods: {
    onDeleteUser() {
      this.loading = true
      this.$store.dispatch('admin/deleteAdmin', {id: this.user.id })
        .then((response) => {
          this.$emit('success',response)
          this.$parent.$emit('close')
        })
        .catch((error) => {
          error
          this.$emit('failed', error)
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style>

</style>