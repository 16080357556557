<template>
  <div class="p-6">
    <div class="flex justify-between">
      <div class="text-2xl font-bold mb-6">
        Utilisateurs
      </div>
      <m-button @click="onAddUserClick" :classes="'flex items-center space-x-2 text-white font-bold text-xs bg-blueLink rounded-lg p-2.5 px-4 w-full'" :text="'Ajouter un utilisateur'">
          <span slot="icon">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12 8.66634H8.66671V11.9997C8.66671 12.3663 8.36671 12.6663 8.00004 12.6663C7.63337 12.6663 7.33337 12.3663 7.33337 11.9997V8.66634H4.00004C3.63337 8.66634 3.33337 8.36634 3.33337 7.99967C3.33337 7.63301 3.63337 7.33301 4.00004 7.33301H7.33337V3.99967C7.33337 3.63301 7.63337 3.33301 8.00004 3.33301C8.36671 3.33301 8.66671 3.63301 8.66671 3.99967V7.33301H12C12.3667 7.33301 12.6667 7.63301 12.6667 7.99967C12.6667 8.36634 12.3667 8.66634 12 8.66634Z" fill="white"/>
            </svg>
          </span>
      </m-button>
    </div>
    
    <div class="bg-white rounded-lg p-4 text-sm shadow-md">
      <div class="">
        <div class="flex justify-between items-center text-fonctGrey mb-3">
            <div class="flex items-center space-x-6">
              <div class="px-4 py-2 w-280 bg-neutral rounded-lg flex justify-between items-center text-sm ">
                  <input @input="onSearch" class="w-full bg-neutral focus:outline-none" v-model="searchText" type="text" placeholder="Rechercher">
                  <span>
                      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="2" y="2" width="16" height="16">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M12.6555 12.0598H13.3138L16.8471 15.6098C17.1888 15.9514 17.1888 16.5098 16.8471 16.8514C16.5055 17.1931 15.9471 17.1931 15.6055 16.8514L12.0638 13.3098V12.6514L11.8388 12.4181C10.6721 13.4181 9.08046 13.9348 7.38879 13.6514C5.07213 13.2598 3.22213 11.3264 2.93879 8.9931C2.50546 5.4681 5.47213 2.50143 8.99713 2.93477C11.3305 3.2181 13.2638 5.0681 13.6555 7.38477C13.9388 9.07643 13.4221 10.6681 12.4221 11.8348L12.6555 12.0598ZM4.5638 8.30977C4.5638 10.3848 6.2388 12.0598 8.3138 12.0598C10.3888 12.0598 12.0638 10.3848 12.0638 8.30977C12.0638 6.23477 10.3888 4.55977 8.3138 4.55977C6.2388 4.55977 4.5638 6.23477 4.5638 8.30977Z" fill="black"/>
                      </mask>
                      <g mask="url(#mask0)">
                      <rect width="20" height="20" fill="#858C94"/>
                      </g>
                      </svg>
                  </span>
              </div>
              <!-- <m-button :classes="'flex items-center space-x-2 text-white font-bold text-xs bg-primary-100 rounded-lg p-2.5 px-4 w-full'" load :activateLoad="request" :text="'Télécharger'">
                  <span slot="icon">
                      <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M10.5 6.33301H11.56C12.1533 6.33301 12.4466 7.05301 12.0266 7.47301L8.96665 10.533C8.70665 10.793 8.28665 10.793 8.02665 10.533L4.96665 7.47301C4.54665 7.05301 4.84665 6.33301 5.43998 6.33301H6.49998V2.99967C6.49998 2.63301 6.79998 2.33301 7.16665 2.33301H9.83331C10.2 2.33301 10.5 2.63301 10.5 2.99967V6.33301ZM4.49998 13.6663C4.13331 13.6663 3.83331 13.3663 3.83331 12.9997C3.83331 12.633 4.13331 12.333 4.49998 12.333H12.5C12.8666 12.333 13.1666 12.633 13.1666 12.9997C13.1666 13.3663 12.8666 13.6663 12.5 13.6663H4.49998Z" fill="white"/>
                  </svg>
                  </span>
              </m-button> -->
            </div>
            <div v-if="pagination.total" class="flex space-x-7 items-center justify-end font-medium">
                <span>{{ (pagination.currentPage - 1)* pagination.perPage + 1 }}-{{(pagination.currentPage * pagination.perPage) > pagination.total ? pagination.total : (pagination.currentPage * pagination.perPage) }} sur {{ pagination.total }}</span>
                <span class="flex items-center space-x-2">
                    <svgicon @click="prev(pagination.currentPage)" :color="pagination.currentPage != 1 ? 'black' : '#BAC7D5'" class="cursor-pointer" name="arrowPagePrev" width="24" height="24"></svgicon>
                    <svgicon @click="next(pagination.currentPage)" :color="pagination.currentPage == pagination.totalPage ? '#BAC7D5' : 'black'" class="cursor-pointer" name="arrowPageNext" width="24" height="24"></svgicon>
                </span>
            </div>
        </div>
        <v-table
        :emptyDataMessage="'Aucune utilisateur'"
        :css="{tableClass: 'bg-white'}"
        :columns="fields"
        apiMode
        :loadOnApiMode="loadAdminOnApiMode"
        dataPath="data"
        paginationPath="pagination"
        :perPage="pagination.perPage"
        rowClass="text-sm hover:bg-tabBg  border-b border-tabBorder"
        :appendParams="appendParams"
        ref="table" >
            <template v-slot:action="{rowData}">
              <div class="flex">
                  <p @click="onUpdateUserCkick(rowData)" class="text-blueLink flex underline cursor-pointer mr-4"> <span class="mr-2"><EditIcon /></span> Modifier</p>
                  <p @click="onDeleteUserCkick(rowData)" class="text-redLink flex items-center underline cursor-pointer"><span class="mr-2"><TrashIcon /></span> Supprimer</p>
              </div>
            </template>
        </v-table>
      </div>
    </div>
    <Modal
      centered
      :show="showModalAction"
      :size="modalSize"
      :css="{modalClass: 'rounded-2xl'}"
      @close="showModalAction = false"
    >
      <template v-if="comp.name">
        <component
          :is="comp.name"
          v-on="comp.event"
          v-bind="{
            ...comp.props,
            ...comp.attr
          }"
        >
        </component>
      </template>
      <!-- <modal-transaction-detail /> -->
    </Modal>
  </div>
</template>

<script>
import CreateAdminForm from './components/CreateAdminForm.vue'
import DeleteConfirmation from './components/DeleteConfirmation.vue'
import UpdateAdminForm from './components/UpdateAdminForm.vue'
import SuccessMessage from './components/SuccessMessage.vue'
import EditIcon from '@/assets/icons/edit.svg'
import TrashIcon from '@/assets/icons/trash.svg'
export default {
  components: { CreateAdminForm, UpdateAdminForm, DeleteConfirmation, SuccessMessage, EditIcon, TrashIcon },
  data(){
    return {
      request: false,
      showModalAction: false,
      modalSize: 'w-328 md:w-533',
      comp: {
        name: null,
        props: {},
        attr: {}
      },
      pagination: {
        currentPage: 1,
        perPage: 10,
        total: 0,
        totalPage: 1
      },
      searchText: '',
      appendParams: {},
      fields: [
          {
            name: 'username',
            title: 'Identifiant',
            titleClass: 'text-fonctGrey py-2 font-normal border-b border-neutralLight',
            width: "15%",
            dataClass: 'py-3.5',
        },
        {
            name: 'firstName',
            title: 'Nom',
            titleClass: 'text-fonctGrey py-2 font-normal border-b border-neutralLight',
            width: "15%",
            dataClass: 'py-3.5 text-inkNormal-simple',
            transform: ({firstName, lastName}) => {
              let name = ''
              if (firstName) {
                name+= firstName
              }
              if (lastName) {
                name+= ' '+ lastName
              }
              return name
            }
        },
        {
            name: 'email',
            title: 'Email',
            titleClass: 'text-fonctGrey py-2 font-normal border-b border-neutralLight',
            width: "20%",
            dataClass: 'py-3.5 text-inkNormal-simple',
        },
        {
            name: 'roles',
            title: 'Role',
            titleClass: 'text-fonctGrey py-2 font-normal border-b border-neutralLight',
            width: "20%",
            dataClass: 'py-3.5 text-inkNormal-simple',
            transform: ({roles}) => {
              if (Array.isArray(roles) && roles.length > 0) {
                return roles[0].name === 'admin' ? 'Administrateur': ''
              }
              return ''
            }
        },
        {
            name: '__slot:action',
            title: '',
            titleClass: 'text-fonctGrey py-2 font-normal border-b border-neutralLight',
            width: "15%",
            dataClass: 'text-right px-3'
        }
      ],
    }
  },
  methods: {
    onSearch(){
        this.appendParams.search = this.searchText;
        this.refreshTables()
    },
    
    prev (current) {
      console.log('current --->', current);
      if (current > 0) {
        console.log('prev -->', current - 1);
        this.$refs.table.changePage(current - 1)
      }
    },

    next (current) {
      console.log('current -->',current);
      if (current < this.pagination.totalPage) {
        console.log('next -->', current + 1);
        this.$refs.table.changePage(current + 1)
      }
    },

    refreshTables(){
        this.$refs.table.refresh();
    },

    onAddUserClick () {
      this.comp = {
        name: 'CreateAdminForm',
        event: {
          'success': this.onSendInvitationSuccess
        }
      }
      this.modalSize = 'w-328 md:w-533'
      this.showModalAction = true;
    },

    onUpdateUserCkick(rowData){
      this.comp = {
        name: 'UpdateAdminForm',
        props: { user: rowData }
      }
      this.modalSize = 'w-328 md:w-533'
      this.showModalAction = true;
    },

    onDeleteUserCkick(rowData) {
      this.comp = {
        name: 'DeleteConfirmation',
        props: { user: rowData },
        event: {
          'success': this.onDeleteSuccess
        }
      }
      this.modalSize = 'sm'
      this.showModalAction = true;
    },

    loadAdminOnApiMode ({options}) {
      const params = options['params']
      console.log('params -->', params);
      
      return new Promise((resolve, reject) => {
          this.$store.dispatch('admin/getPaginateAdmin', {params})
            .then(data => {
              this.pagination = data.pagination
              console.log('pagination ----->', this.pagination);
              resolve({data})
            })
            .catch(error => {
              reject(error)
            })
      })
    },  

    onSendInvitationSuccess (event) {
      this.comp = {
        name: 'SuccessMessage',
        props: { user: event }
      }
      this.modalSize = 'sm'
      this.showModalAction = true;
    },

    onDeleteSuccess () {
      this.refreshTables()
    }
  },
}
</script>

<style>

</style>